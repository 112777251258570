// Components
import DishForm from '@/components/elements/dishes/DishForm'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import onboardingMixin from '@/mixins/onboardingMixin'
import uiMixin from '@/mixins/uiMixin'
import intercomMixin from '@/mixins/intercomMixin'

export default {
  name: 'OnboardingProduct',
  components: { SectionInformationBox, VuetifyToolBar },
  mixins: [onboardingMixin, uiMixin, intercomMixin],
  data() {
    return {
      id: this.$route.params.id || null // Category ID
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('completed-product-form')
  },
  methods: {
    /**
     * Usuario pulsa en el botón de acción
     */
    handleClickButton() {
      this.showFormDialog()
    },
    /**
     * Obtenemos todo lo necesario para inicializar el componente
     */
    async getEveryNeededData() {
      // Capturamos evento
      this.$root.$on('completed-product-form', this.captureProductFormCompleted)

      if (!this.id) {
        // Marcamos paso como completado
        await this.setSectionCompleted('product')
      }
    },
    /**
     * Mostramos el diálogo del formulario
     */
    showFormDialog() {
      // Si no tiene numero o place le pedimos que lo complete
      this.modifyAppDialog({
        title: 'Crear Producto',
        contentComponent: DishForm,
        contentComponentProps: { categoryId: this.id },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Tratamos el evento de completitud del formulario de producto
     */
    async captureProductFormCompleted() {
      // Modificamos variable de Intercom
      this.intercomUpdate({
        bakarta_onboarding_dish: true
      })
      // Cerramos modal
      this.modifyAppDialog({
        visible: false
      })
      // Establecemos paso como completado
      await this.setSectionCompleted('product')
    }
  }
}
